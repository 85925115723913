import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { trackPageView } from '../../../utils/tracking';

import { useRecoilValue } from 'recoil';
import { orderState, vmState } from '../../../state/recoilState';
import { BasicScreenProps } from '../../../types';
import { screenLog } from '../../../utils/general';
import { Button, SmallButton, SmallPrimaryButton } from '../../common/Button';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import PrimaryButton from '../../common/PrimaryButton';

interface CompleteScreenProps {
    onRepeatPurchase: () => void;
    onFeedback?: () => void;
    showFeedback?: boolean;
    onGetReceipt: () => void;
    showReceipt?: boolean;
}

const CanvasObject = styled.object`
    margin: -1vmax 0 0 0;
    width: min(90vw, 60vh);
    height: 30vh;
`;
const ScreenTitle = styled.h1`
    font-size: min(6.5vw, 42px);
    font-weight: 400;
    color: #1b3f92;
    padding-bottom: 4rem;
    text-align: center;
`;
const ScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 75vh;
    font-family: 'Commissioner' 'Franklin Gothic Medium', 'Arial Narrow', Arial,
        sans-serif;
`;

const CompletedScreen = (props: CompleteScreenProps) => {
    const {
        onRepeatPurchase,
        onFeedback,
        showFeedback,
        onGetReceipt,
        showReceipt,
    } = props;
    const { t } = useTranslation();
    const order = useRecoilValue(orderState);
    const vmInfo = useRecoilValue(vmState);
    const isAds = Boolean(vmInfo.ads && JSON.parse(vmInfo.ads)?.success);

    useEffect(() => {
        trackPageView('completed', { orderId: order.id, ...order });
        screenLog('Completed screen');
    }, []);
    //-------------ADSENSE--------------//
    useEffect(() => {
        const adElementBig = document.querySelector(
            '.googleAds_CustomBig'
        ) as HTMLElement;
        // if (adElementBig && isAds) {
        //     adElementBig.style.display = 'flex';
        //     adElementBig.style.justifyContent = 'center';
        //     adElementBig.style.position = 'fixed';
        //     adElementBig.style.bottom = '35vh';
        //     adElementBig.style.opacity = '1';
        // }
        return () => {
            if (adElementBig) {
                adElementBig.style.display = 'none';
                adElementBig.style.opacity = '0';
            }
        };
    }, []);
    //-------------ADSENSE--------------//

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Thank you')}</ScreenTitle>
            {isAds &&
                <>
                    <script
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1847267298539084"
                        crossOrigin="anonymous"
                    ></script>
                    <div className="googleAds_CustomBig">
                        <ins
                            className="adsbygoogle"
                            style={{
                                display: 'inline-block',
                                width: '300px',
                                height: '250px',
                            }}
                            data-ad-client="ca-pub-1847267298539084"
                            data-ad-slot="9637807206"
                        ></ins>
                    </div>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </>
            }
            <ContentBox>
                {!isAds && <CanvasObject data='/tick.html' />}

                <ButtonBox>
                    {showFeedback && (
                        <PrimaryButton type="button" onClick={onFeedback}>
                            {t('Feedback')}
                        </PrimaryButton>
                    )}

                    {showReceipt && !showFeedback ? (
                        <Button onClick={onGetReceipt}>
                            {t('Get fiscal receipt')}
                        </Button>
                    ) : (
                        showReceipt && (
                            <SmallButton
                                onClick={onGetReceipt}
                                style={{ marginRight: '2px' }}
                            >
                                {t('Get fiscal receipt')}
                            </SmallButton>
                        )
                    )}

                    {showReceipt && showFeedback ? (
                        <SmallButton
                            onClick={onRepeatPurchase}
                            style={{ marginLeft: '2px' }}
                        >
                            {t('Repeat_purchase')}
                        </SmallButton>
                    ) : (
                        <Button onClick={onRepeatPurchase}>
                            {t('Repeat_purchase')}
                        </Button>
                    )}
                </ButtonBox>
            </ContentBox>
        </ScreenWrapper>
    );
};

export default CompletedScreen;
